import React, { useState } from "react";

const HeightRangeCalculator = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  console.log("data", data);
  // Define the height ranges
  const heightRanges = [
    { min: 90, max: 95 },
    { min: 85, max: 90 },
    { min: 80, max: 85 },
    { min: 75, max: 80 },
    { min: 70, max: 75 },
    { min: 65, max: 70 },
    { min: 60, max: 65 },
    { min: 55, max: 60 },
    { min: 50, max: 55 },
    { min: 45, max: 50 },
    { min: 40, max: 45 },
    { min: 35, max: 40 },
    { min: 30, max: 35 },
  ];

  // Initialize dictionaries to store the sum for each range for each calculation type
  const rangeSums = heightRanges.reduce((acc, range) => {
    const rangeLabel = `${range.min}_${range.max}`;
    acc[rangeLabel] = {
      cantitate: 0,
      production: 0,
      totalInDelivery: 0,
      calculated: 0,
    };
    return acc;
  }, {});

  // Perform calculations for each item in the data array
  data.forEach((item) => {
    // Skip items with "BIC TUBE" in `den_produs`
    if (item.den_produs && item.den_produs.toLowerCase().includes("bic tub")) {
      return;
    }

    // Additional calculations based on total_in_delivery, cantitate, and production
    const totalInDelivery =
      item.total_in_delivery / (item.total_boxes_or_pallets || 0);
    const cantitateValue = item.cantitate / (item.total_boxes_or_pallets || 0);
    const productionValue =
      item.total_in_production / (item.total_boxes_or_pallets || 0);

    // Check if `den_produs_en` contains both "bic tire" and "vee tire"
    const useDepth =
      item.den_produs_en &&
      item.den_produs_en.toLowerCase().includes("vee tir");

    // Calculate length in meters based on condition
    const lengthInMeters =
      (useDepth
        ? item.quantity_dimensions_per_unit.height
        : item.quantity_dimensions_per_unit.length) / 100;

    const totalLengthVolumeCantitate =
      item.total_boxes_or_pallets === 25 &&
      item.den_produs_en.includes("VEE TIR")
        ? Math.ceil(item.cantitate / 50) *
          (item.quantity_dimensions_per_unit.depth / 100)
        : cantitateValue * lengthInMeters;

    const totalLengthVolumeProduction =
      item.total_boxes_or_pallets === 25 &&
      item.den_produs_en.includes("VEE TIR")
        ? Math.ceil(item.total_in_production / 50) *
          (item.quantity_dimensions_per_unit.depth / 100)
        : productionValue * lengthInMeters;

    const totalLengthVolumeInDelivery =
      item.total_boxes_or_pallets === 25 &&
      item.den_produs_en.includes("VEE TIR")
        ? Math.ceil(item.total_in_delivery / 50) *
          (item.quantity_dimensions_per_unit.depth / 100)
        : totalInDelivery * lengthInMeters;

    // Sum of cantitate, production, and totalInDelivery for the calculated field
    const totalLengthVolumeCalculated =
      totalLengthVolumeCantitate +
      totalLengthVolumeProduction +
      totalLengthVolumeInDelivery;

    // Check the height and add the total length volumes to the corresponding range for each calculation type
    const length = !useDepth
      ? item.quantity_dimensions_per_unit.depth
      : item.quantity_dimensions_per_unit.height * 2 + 6;

    for (const range of heightRanges) {
      if (
        length > range.min &&
        length <= range.max &&
        item.total_boxes_or_pallets !== 10
      ) {
        const rangeLabel = `${range.min}_${range.max}`;
        rangeSums[rangeLabel].cantitate += totalLengthVolumeCantitate;
        rangeSums[rangeLabel].production += totalLengthVolumeProduction;
        rangeSums[rangeLabel].totalInDelivery += totalLengthVolumeInDelivery;
        rangeSums[rangeLabel].calculated += totalLengthVolumeCalculated;
        break;
      }
    }
  });

  // Toggle popup open/close state
  const togglePopup = () => setIsOpen(!isOpen);

  return (
    <div style={styles.wrapperMod}>
      <button onClick={togglePopup} style={styles.buttonOpen}>
        Show Width Range Calculations
      </button>

      {isOpen && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <h2>Width Range Calculations (in meters)</h2>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th>Range</th>
                  <th>In Stock</th>
                  <th>In Production</th>
                  <th>In Delivery</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(rangeSums).map(([range, sums]) => (
                  <tr key={range}>
                    <td>{range.replace("_", " - ")}</td>
                    <td>{sums.cantitate.toFixed(2)} m</td>
                    <td>{sums.production.toFixed(2)} m</td>
                    <td>{sums.totalInDelivery.toFixed(2)} m</td>
                    <td>{sums.calculated.toFixed(2)} m</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={togglePopup} style={styles.closeButton}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeightRangeCalculator;

// Inline styles for modal and table
const styles = {
  wrapperMod: {
    display: "flex",
  },
  buttonOpen: {
    display: "flex",
    alignItems: "center",
    background: "#0958d9",
    color: "#fff",
    padding: "7px 15px",
    borderRadius: "5px",
    border: "1px solid transparent",
  },
  modal: {
    position: "fixed",
    zIndex: 10,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "500px",
    textAlign: "center",
  },
  closeButton: {
    marginTop: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    fontWeight: "bold",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
  },
};
