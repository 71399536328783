import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export const Decision = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("user");

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/stock");
    } else {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);
  return null;
};
