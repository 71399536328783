import React, { useState } from "react";
import styled from "styled-components";

const Button = styled.div`
  position: fixed;
  bottom: 10px;
  width: 100%;
  left: calc(100% - 50px);
  height: 50px;
  z-index: 1;
  cursor: pointer;
  span {
    width: 100px;
    height: 100px;
    svg {
      max-width: 100%;
      max-height: 100%;
      path {
        fill: rgb(56, 158, 13);
      }
    }
  }
`;

const ScrollButton = () => {
  const [visible, setVisible] = useState(true);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 0) {
      setVisible(false);
    } else if (scrolled <= 0) {
      setVisible(true);
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Button>
      <span
        onClick={scrollToBottom}
        style={{ display: visible ? "inline" : "none" }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM127 281c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l71 71L232 136c0-13.3 10.7-24 24-24s24 10.7 24 24l0 182.1 71-71c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 393c-9.4 9.4-24.6 9.4-33.9 0L127 281z" />
        </svg>
      </span>
    </Button>
  );
};

export default ScrollButton;
