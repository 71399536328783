import React from "react";
import { DeleteIcon } from "./deleteIcon";
import styled from "styled-components";

const WrapIcon = styled.div`
  width: 20px;
  position: absolute;
  left: -11px;
  svg {
    max-width: 100%;
    height: auto;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const DeleteItem = (props) => {
  const { productId, setData, showNotification } = props;
  let baseURL;

  if (window.location.hostname === "localhost") {
    baseURL = "http://localhost:5000";
  } else {
    baseURL = "https://stock.veerubber.ro";
  }
  const handleDeleteProduct = async (productId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this product?",
    );
    if (confirmDelete) {
      try {
        await fetch(`${baseURL}/api/product/${productId}`, {
          method: "DELETE",
        });
        // Remove the deleted product from the data state
        setData((prevData) =>
          prevData.filter((item) => item._id !== productId),
        );
        showNotification("Product deleted successfully");
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    }
  };
  return (
    <WrapIcon onClick={() => handleDeleteProduct(productId)}>
      <DeleteIcon />
    </WrapIcon>
  );
};
