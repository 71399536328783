import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import styled from "styled-components";
import calculateOrderBox from "../helpers/orderBoxCalcHelper";

const Wrap = styled.div`
  background: #0958d9;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
    background: #4096ff;
    border: 1px solid transparent;
  }
`;
export const ExportPdf = (props) => {
  const { filteredData } = props;

  const handleExportToPdf = () => {
    const doc = new jsPDF();

    // Add title and current date
    doc.setFontSize(18);
    doc.text("Order products agroverde", 14, 22);
    doc.setFontSize(12);
    doc.text(`Date: ${new Date().toLocaleDateString()}`, 14, 32);

    // Define the table column headers and data
    const columns = ["Name product", "Quantity"];
    const rows = filteredData
      .filter((e) => Number(e.minStock) - Number(e.cantitate) > 0)
      .sort((a, b) => {
        const nameA = a.den_produs_en || a.den_produs;
        const nameB = b.den_produs_en || b.den_produs;
        return nameA.localeCompare(nameB);
      })
      .map((item) => [
        item.den_produs_en || item.den_produs,
        isNaN(calculateOrderBox(item)) ||
        calculateOrderBox(item) -
          item.total_in_production -
          item.total_in_delivery <=
          0
          ? 0
          : calculateOrderBox(item) -
            item.total_in_production -
            item.total_in_delivery,
      ]);

    // Add table to PDF
    doc.autoTable({
      startY: 40,
      head: [columns],
      body: rows,
    });

    // Get current date and time for the file name
    const currentDate = new Date()
      .toISOString()
      .replace(/:/g, "-")
      .replace("T", "_")
      .slice(0, 19);
    const fileName = `agroverde_order_${currentDate}.pdf`;

    // Save the PDF
    doc.save(fileName);
  };

  return (
    <div style={{ display: "flex" }}>
      <Wrap onClick={handleExportToPdf}>Download Order List PDF</Wrap>
    </div>
  );
};
