import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { WrapperLogin } from "./login-styled";

export const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  let baseURL;

  if (window.location.hostname === "localhost") {
    baseURL = "http://localhost:5000";
  } else {
    baseURL = "https://stock.veerubber.ro";
  }

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("user", JSON.stringify(data.user));
        navigate("/stock");
      } else {
        setError("Invalid username or password");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Internal server error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <WrapperLogin>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <h3>Login to see stocks</h3>
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        {error && <div>{error}</div>}
        <button type="submit" disabled={loading}>
          Login
        </button>
      </form>
    </WrapperLogin>
  );
};
