import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const ProductStatisticsChart = ({ statistics }) => {
  // Check if statistics is not an array or is empty/null/undefined
  if (!Array.isArray(statistics) || statistics.length === 0) {
    return null; // Don't render anything if statistics is invalid or empty
  }

  // Transform the data to a more readable format with "Month Year"
  const transformedData = statistics.map((stat) => {
    const year = stat.anluna.slice(0, 4);
    const month = stat.anluna.slice(4, 6);
    const formattedDate = `${month}/${year}`;

    return {
      date: formattedDate,
      quantity: stat.cantitate,
    };
  });

  return (
    <ResponsiveContainer width={500} height={200}>
      <LineChart data={transformedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Line
          type="monotone"
          dataKey="quantity"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        >
          {/* Adding LabelList to show values for all points */}
          <LabelList
            dataKey="quantity"
            position="top"
            content={({ x, y, value }) => (
              <text
                x={x}
                y={y - 5}
                fill="#000"
                textAnchor="middle"
                fontSize={12}
                fontWeight="bold"
              >
                {value}
              </text>
            )}
          />
        </Line>
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ProductStatisticsChart;
