const calculateVolume = (item, packages) => {
  return (
    item.quantity_dimensions_per_unit &&
    item.quantity_dimensions_per_unit.depth !== "" &&
    item.quantity_dimensions_per_unit.depth !== 0 &&
    item.quantity_dimensions_per_unit.height !== "" &&
    item.quantity_dimensions_per_unit.height !== 0 &&
    item.quantity_dimensions_per_unit.length !== "" &&
    item.quantity_dimensions_per_unit.length !== 0 &&
    (
      ((+item.quantity_dimensions_per_unit.depth *
        +item.quantity_dimensions_per_unit.height *
        +item.quantity_dimensions_per_unit.length) /
        1000000) *
      packages
    ).toFixed(3)
  );
};

export { calculateVolume };
