import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import { App } from "./App";
import { Register } from "./register";
import { LoginForm } from "./login";
import { Decision } from "./Decision";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Decision />,
  },
  {
    path: "/stock",
    element: <App />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/login",
    element: <LoginForm />,
  },{
  path: "*",
    element: <App />,
}
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
