import React, { useState } from "react";
import {
  Wrapper,
  WrapButton,
  SaveButton,
  WrapButtons,
  CancelButton,
} from "./addnewitem-styled";

export const AddNewItemNoNexus = (props) => {
  const { showNotification, setData } = props;
  let baseURL;
  if (window.location.hostname === "localhost") {
    baseURL = "http://localhost:5000";
  } else {
    baseURL = "https://stock.veerubber.ro";
  }
  const [showInput, setShowInput] = useState(true);
  const [name, setName] = useState("");
  const [pcs, setPcs] = useState("");
  // const [noNexus, setNonexus] = useState([]);
  const [disable, setDisable] = useState(false);

  const handleClick = () => {
    setShowInput(!showInput);
  };

  const createProduct = async () => {
    setDisable(true);

    console.log("create product here", "732104(1)");
    try {
      const response = await fetch(`${baseURL}/api/new-product`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          den_produs: name,
          den_produs_en: "", // Set to empty string or provide the English name
          id_furnizor: "732104(1)", // Provide the supplier ID
          cantitate: pcs,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create new product");
      }

      // Reset input fields
      setName("");
      setPcs("");
      setDisable(false);

      const updatedProductList = await response.json();

      showNotification(updatedProductList.message);
      setData((prevData) => [...prevData, updatedProductList.product]);
      setShowInput(true);
    } catch (error) {
      console.error("Error creating new product:", error);
      setShowInput(true);
    }
  };

  // useEffect(() => {
  //   fetch(`${baseURL}/api/new-product-list`)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       // Handle the fetched Nonexus data
  //       console.log("Nonexus data:", data);
  //       setNonexus(data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching Nonexus data:", error);
  //     });
  // }, [baseURL]);

  return (
    <Wrapper>
      {/*<div>*/}
      {/*  {noNexus.map((value, index) => {*/}
      {/*    return (*/}
      {/*      <div>*/}
      {/*        <span key={index}>{value.den_produs}</span>*/}
      {/*        <span>{value.cantitate}</span>*/}
      {/*      </div>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</div>*/}
      <br />
      <br />

      {showInput ? (
        <WrapButton onClick={() => handleClick()}>Add new</WrapButton>
      ) : (
        <div className={disable ? "make-dis" : "mak-enable"}>
          <input
            type="text"
            value={name}
            placeholder="Name product"
            className="inp-name"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="number"
            onWheel={(event) => event.target.blur()}
            value={pcs}
            placeholder="Stock(pcs)"
            className="inp-pcs"
            onChange={(e) => setPcs(e.target.value)}
          />
          <WrapButtons>
            <SaveButton onClick={() => createProduct()}>
              Save product
            </SaveButton>
            <CancelButton onClick={() => handleClick()}>Cancel</CancelButton>
          </WrapButtons>
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
    </Wrapper>
  );
};
