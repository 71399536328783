import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4caf50;
  color: white;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  animation: slideIn 0.5s ease forwards;
  @-webkit-keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
`;

const Icon = styled.svg`
  width: 24px;
  height: 24px;
  fill: white;
  margin-right: 8px;
`;

export const Notification = ({ message }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(false);
    }, 3000); // Adjust the duration as needed

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Wrapper className={`notification ${show ? "show" : "hide"}`}>
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15l-5-5 1.41-1.41L11 14.17l6.59-6.59L18 9l-7 7z" />
      </Icon>

      <span>{message}</span>
    </Wrapper>
  );
};
