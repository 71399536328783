const calculatePackages = (item) => {
  const order = Number.isInteger(
    Math.abs(item.cantitate - item.minStock) / +item.total_boxes_or_pallets,
  )
    ? Math.abs(item.cantitate - item.minStock)
    : Math.floor(
        Math.abs(item.cantitate - item.minStock) / item.total_boxes_or_pallets,
      ) *
        item.total_boxes_or_pallets +
      +item.total_boxes_or_pallets;

  // return (
  //   (order - item.total_in_production - item.total_in_delivery) /
  //   item.total_boxes_or_pallets
  // );
  // return order % 2 === 0
  //   ? (order - item.total_in_production - item.total_in_delivery) /
  //       item.total_boxes_or_pallets
  //   : Math.floor(
  //       (order - item.total_in_production - item.total_in_delivery) /
  //         item.total_boxes_or_pallets,
  //     ) +
  //       (item.total_boxes_or_pallets === 1 ||
  //       Number.isInteger(order / item.total_boxes_or_pallets)
  //         ? 0
  //         : 1);
  function makeDivisible(divisor, dividend) {
    // Calculate the remainder
    let remainder = divisor % dividend;

    // If remainder is zero, the number is already divisible
    if (remainder === 0) {
      return divisor;
    } else {
      // Calculate the number of digits to add to the divisor
      let digitsToAdd = dividend - remainder;

      // Add the required digits to make it divisible
      let result = divisor + digitsToAdd;

      return result;
    }
  }
  let divisor = order - item.total_in_production - item.total_in_delivery;
  let dividend = item.total_boxes_or_pallets;
  return makeDivisible(divisor, dividend) / item.total_boxes_or_pallets;
};

export { calculatePackages };
