const deliveryPackage = (item) => {
  function makeDivisible(divisor, dividend) {
    // Calculate the remainder
    let remainder = divisor % dividend;

    // If remainder is zero, the number is already divisible
    if (remainder === 0) {
      return divisor;
    } else {
      // Calculate the number of digits to add to the divisor
      let digitsToAdd = dividend - remainder;

      // Add the required digits to make it divisible
      return divisor + digitsToAdd;
    }
  }
  let divisor = item.total_in_delivery;
  let dividend = item.total_boxes_or_pallets;
  return makeDivisible(divisor, dividend) / item.total_boxes_or_pallets;
};

const deliveryVolume = (item) => {
  return (
    item.quantity_dimensions_per_unit &&
    item.quantity_dimensions_per_unit.depth !== "" &&
    item.quantity_dimensions_per_unit.depth !== 0 &&
    item.quantity_dimensions_per_unit.height !== "" &&
    item.quantity_dimensions_per_unit.height !== 0 &&
    item.quantity_dimensions_per_unit.length !== "" &&
    item.quantity_dimensions_per_unit.length !== 0 &&
    (
      ((+item.quantity_dimensions_per_unit.depth *
        +item.quantity_dimensions_per_unit.height *
        +item.quantity_dimensions_per_unit.length) /
        1000000) *
      deliveryPackage(item)
    ).toFixed(3)
  );
};

export { deliveryPackage, deliveryVolume };
