import React from "react";
import ProductStatisticsChart from "./ProductStatisticsChart";
import "./Statistics.css";

const Statistics = ({
  item,
  hoveredItemId,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  return (
    <div
      className="statistics"
      onMouseEnter={() => handleMouseEnter(item._id)}
      onMouseLeave={handleMouseLeave}
      style={{ position: "relative" }}
    >
      {item.statistics && item.statistics.length > 0 ? (
        <div>
          {/* Display the sum of "cantitate" */}
          <span>
            {item.statistics.reduce((total, stat) => total + stat.cantitate, 0)}
          </span>
        </div>
      ) : (
        <span style={{ fontSize: "9px", whiteSpace: "nowrap" }}>
          No statistics
        </span>
      )}

      {hoveredItemId === item._id && (
        <div className="chart-popup">
          <ProductStatisticsChart statistics={item.statistics} />
        </div>
      )}
    </div>
  );
};

export default Statistics;
