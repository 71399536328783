import React from "react";

export const SortDepth = ({ onSortUp, onSortDown, onSortReset }) => {
  return (
    <div className="wrapp_arrows_sort" style={{ display: "flex", gap: "10px" }}>
      {/* Reset Sort Button */}
      <div
        onClick={onSortReset}
        style={{ cursor: "pointer" }}
        aria-label="Reset Sort"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="resetTriangle"
        >
          <path
            d="M18 6L6 18M6 6l12 12"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {/* Sort Ascending Button */}
      <div
        onClick={onSortUp}
        style={{ cursor: "pointer" }}
        aria-label="Sort Ascending"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="triangleUp"
        >
          <path d="M12 4L6 10h12L12 4z" fill="currentColor" />
        </svg>
      </div>

      {/* Sort Descending Button */}
      <div
        onClick={onSortDown}
        style={{ cursor: "pointer" }}
        aria-label="Sort Descending"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="triangleDown"
        >
          <path d="M12 20l6-6H6l6 6z" fill="currentColor" />
        </svg>
      </div>
    </div>
  );
};
