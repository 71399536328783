import React from "react";
import * as XLSX from "xlsx";
import "./ExcelExtractor.css";

const ExcelExtractor = ({ setProductsForStatistics }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });

        // Extracting and processing the sheet data
        const parsedProducts = sheet.slice(1).map((row) => ({
          name: row[0],
          cantitate: row[1] < 0 ? 0 : row[1],
          anluna: row[3]?.toString(),
        }));

        const groupedProducts = {};
        parsedProducts.forEach((product) => {
          if (!groupedProducts[product.name]) {
            groupedProducts[product.name] = {};
          }

          const month = product.anluna;
          if (!groupedProducts[product.name][month]) {
            groupedProducts[product.name][month] = 0;
          }

          groupedProducts[product.name][month] += product.cantitate;
        });

        const productStatisticsArray = Object.keys(groupedProducts).map(
          (name) => ({
            name,
            statistics: Object.keys(groupedProducts[name]).map((month) => ({
              anluna: month,
              cantitate: groupedProducts[name][month],
            })),
          }),
        );

        // Ensure setting the state after processing
        setProductsForStatistics(productStatisticsArray);
      };

      // Read file as an ArrayBuffer
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div className="excel-extractor">
      <label className="file-upload">
        <input type="file" accept=".xls,.xlsx" onChange={handleFileChange} />
        <span>Upload statistics xlsx file</span>
      </label>
    </div>
  );
};

export default ExcelExtractor;
