import styled from "styled-components";
import searchIcon from "./img/btn-search-top.png";

export const Wrapper = styled.div`
  .wrap-table {
    .wrapper-lines {
      display: flex; /* Use flexbox for the child elements */
      width: 100%;
      font-weight: lighter;
      font-size: 14px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      margin: 0 20px;
      padding-top: 10px;
      @media screen and (max-width: 900px) {
        flex-direction: column;
        margin-top: 10px;
        padding-top: 10px;
      }
    }

    .name-product,
    .prod-amount,
    .min-stock,
    .order-prod,
    .prod-dimen,
    .total-m,
    .prod-q-pal,
    .prod-weight-pal,
    .prod-total-weight,
    .prod-date-prod,
    .prod-total-in-ptod,
    .prod-date-delivery,
    .total-q-box-roll,
    .unit-price,
    .prod-in-delivery {
      flex-grow: 1; /* Allow the element to grow and fill available space */
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 900px) {
        width: 100%;
        justify-content: inherit;
      }
    }
  }

  &.marianManager {
    .name-product {
      width: 50%;
    }
  }
  .disable-it {
    pointer-events: none;
    text-align: center;
    input {
      background: rgb(204, 204, 204) !important;
      opacity: 0.5;
      display: inline;
      text-align: center;
    }
  }
  h1 {
    text-align: center;
    @media screen and (max-width: 900px) {
      font-size: 17px;
    }
  }

  .wrapper-lines h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    @media screen and (max-width: 1300px) {
      font-size: 14px;
    }
  }

  .wrapper-lines {
    font-weight: lighter;
    font-size: 14px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    display: flex;
    margin: 0 20px;
    padding-top: 10px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      margin-top: 10px;
      padding-top: 10px;
    }
  }

  .right-part input:not([type="checkbox"]) {
    outline: none !important;
    appearance: none !important;
    -webkit-appearance: none !important;
  }

  .warning-color {
    background: rgb(255, 242, 232);
    color: #000;

    input {
      background: transparent;
      color: #000;
    }
  }

  .inprogress-color {
    background: #82d1a4;
    color: #000;

    input {
      background: transparent;
      color: #000;
    }
  }

  .desktop-menu {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  .show-mobile-menu {
    display: none;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    @media screen and (max-width: 900px) {
      display: initial;
      padding-right: 0;
      width: 100px;
    }
  }

  .amount {
    //padding: 4px 0;
  }

  .amount h2 {
    //transform: translate(-50px, 0px);
    @media screen and (max-width: 900px) {
      transform: none;
    }
  }
  .left-part {
    width: 41%;
    padding: 4px 0;
    @media screen and (max-width: 900px) {
      width: inherit;
    }
  }
  input {
    border: 1px solid rgba(0, 0, 0, 0);
    width: calc(100% - 30px);
    padding: 10px;
    @media screen and (max-width: 900px) {
      width: calc(100% - 120px);
    }

    &:focus {
      border: 1px solid rgb(240, 240, 240);
    }
  }
  .right-part {
    width: 10%;
    padding: 4px 0;
    @media screen and (max-width: 900px) {
      width: inherit;
    }
  }
  .name-product {
    position: relative;
    width: 20%;
    input::placeholder {
      color: rgb(26, 122, 255);
    }
  }
  .prod-amount {
    width: 4%;
  }
  .min-stock {
    width: 6.44%;
    position: relative;
  }
  .statistics {
    color: #050505;
  }
  .order-prod {
    width: 6.44%;
    text-align: center;
    position: relative;
    @media screen and (max-width: 900px) {
      text-align: left;
    }
  }
  .order-prod .show-mobile-menu {
    @media screen and (max-width: 900px) {
      width: 104px;
    }
  }
  .prod-dimen {
    position: relative;
    width: 9.88%;
    padding: 0 10px;
    @media screen and (max-width: 900px) {
      padding: 0;
    }
  }
  .total-m {
    width: 3%;
    padding: 0 6px;
    @media screen and (max-width: 900px) {
      padding: 0;
    }
  }
  .prod-q-pal {
    width: 4.44%;
  }
  .prod-weight-pal {
    width: 6.44%;
  }
  .prod-total-weight {
    width: 6.44%;
  }
  .prod-date-prod {
    width: 8%;
    input {
      width: initial;
      &:hover {
        cursor: pointer;
      }
    }
    padding: 0 6px;
    @media screen and (max-width: 900px) {
      padding: 0;
    }
  }
  .prod-total-in-ptod {
    position: relative;
    width: 6.44%;
  }
  .prod-date-delivery {
    width: 8%;
    input {
      width: initial;
    }
  }
  .prod-in-delivery {
    position: relative;
    width: 4.44%;
  }
  .total-q-box-roll {
    width: 4%;
  }
  .unit-price {
    width: 3%;
  }
  .move-bottom {
    //transform: translateY(10px);
  }
  .name-product,
  .prod-amount,
  .min-stock,
  .order-prod,
  .prod-dimen,
  .total-m,
  .prod-q-pal,
  .prod-weight-pal,
  .prod-total-weight,
  .prod-date-prod,
  .prod-total-in-ptod,
  .prod-date-delivery,
  .total-q-box-roll,
  .unit-price,
  .prod-in-delivery {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 900px) {
      width: inherit;
      justify-content: inherit;
    }
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .hideIt {
    display: none !important;
    width: 0 !important;
    padding: 0 !important;
  }
  .color-green {
    color: rgb(56, 158, 13);
    background-color: rgb(246, 255, 237);
    border: 1px solid rgb(183, 235, 143);
    padding: 0 9px 2px;
    border-radius: 4px;
    @media screen and (max-width: 900px) {
      margin-left: 3px;
    }
  }
  .warning {
    border: 1px solid rgb(255, 187, 150);
    background: rgb(255, 242, 232);
    color: rgb(212, 56, 13);
    padding: 0 9px 2px;
  }
  .search-input {
    background: url(${searchIcon}) no-repeat right 10px center;
    background-size: 15px 15px;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

export const IdNexus = styled.span`
  position: absolute;
  top: -10px;
  left: 14px;
  color: rgba(0, 0, 0, 0.8);
  @media screen and (max-width: 900px) {
    left: 0;
  }
  &:hover {
    cursor: pointer;
  }
  img {
    width: 10px;
    height: 10px;
  }
`;

export const WrapSetProdate = styled.div`
  margin: 30px 20px;
  input {
    max-width: 100px;
    display: block;
    border: 1px solid #000;
    margin-bottom: 5px;
    border-radius: 4px;
    &:hover {
      cursor: pointer;
    }
  }
  button {
    background: #0958d9;
    color: #fff;
    padding: 7px 15px;
    border-radius: 5px;
    border: 1px solid transparent;
    &:hover {
      cursor: pointer;
      background: #4096ff;
      border: 1px solid transparent;
    }
    &.cancel-date {
      background: transparent;
      color: rgb(255, 77, 79);
      border: 1px solid rgb(255, 77, 79);
      &:hover {
        background: rgb(255, 77, 79);
        color: #fff;
      }
    }
    &.save-date {
      color: rgb(0, 183, 74);
      background: transparent;
      border: 1px solid rgb(0, 183, 74);
      margin-right: 15px;
      &:hover {
        color: #fff;
        background: rgb(0, 183, 74);
      }
    }
  }
`;
export const WrapTableItem = styled.div``;
export const WrapSearchExport = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
`;

export const WrapFactoryDetails = styled.div`
  position: absolute;
  padding: 0 3px;
  top: -5px;
  background: orange;
  white-space: nowrap;
`;

export const TotalPrice = styled.div`
  padding: 0 3px;
  position: absolute;
  top: -5px;
  background: orange;
  white-space: nowrap;
`;

export const WrapDropdown = styled.div`
  z-index: 2;
  &.dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-button {
    padding: 10px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  .dropdown-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    width: 200px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .dropdown-item input {
    margin-right: 10px;
    width: 15px;
    cursor: pointer;
  }
`;
