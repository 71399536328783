import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 20px 20px;
  padding-top: 10px;
  border-top: 2px solid #000;
  input {
    border-bottom: 0.5px solid #0958d9;
    margin-bottom: 10px;
    outline: none;
    &:focus {
      border: none;
      border-bottom: 0.5px solid #0958d9;
    }
    &.inp-pcs {
      width: 63px;
    }
    &.inp-name {
    }
  }
  &.make-dis {
    pointer-events: none;
    filter: grayscale(1);
  }
`;
export const WrapButton = styled.div`
  background: #0958d9;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  border: 1px solid transparent;
  width: fit-content;
  &:hover {
    cursor: pointer;
    background: #4096ff;
    border: 1px solid transparent;
    cursor: pointer;
  }
`;

export const WrapButtons = styled.div`
  display: flex;
`;

export const SaveButton = styled.div`
  padding: 7px 15px;
  border-radius: 5px;
  color: rgb(0, 183, 74);
  background: transparent;
  border: 1px solid rgb(0, 183, 74);
  margin-right: 15px;
  &:hover {
    color: #fff;
    background: rgb(0, 183, 74);
    cursor: pointer;
  }
`;

export const CancelButton = styled.div`
  padding: 7px 15px;
  border-radius: 5px;
  background: transparent;
  color: rgb(255, 77, 79);
  border: 1px solid rgb(255, 77, 79);
  &:hover {
    background: rgb(255, 77, 79);
    color: #fff;
    cursor: pointer;
  }
`;
