const calculateOrderBox = (item) => {
  return Number.isInteger(
    Math.abs(item.cantitate - item.minStock) / +item.total_boxes_or_pallets,
  )
    ? Math.abs(item.cantitate - item.minStock)
    : Math.floor(
        Math.abs(item.cantitate - item.minStock) / item.total_boxes_or_pallets,
      ) *
        item.total_boxes_or_pallets +
        +item.total_boxes_or_pallets;
};

export default calculateOrderBox;
